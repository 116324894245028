import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Grid, Icon, IconButton, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import YoloLogo from "../../assets/images/yolo_logo";
import Spacer from "../../components/spacer.component";
import Text from "../../components/text.component";

const StyledFacebookIcon = styled(FacebookIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  height: "30px",
}));

const StyledInstagramIcon = styled(InstagramIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  height: "30px",
}));

const StyledMainBox = styled(Box)(({ isMobile }) => ({
  display: "flex",
  justifyContent: isMobile ? "center" : "space-between",
  flexDirection: isMobile ? "column" : "row",
}));

const StyledRowBox = styled(Box)(({ isMobile }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: isMobile ? "column" : "row",
}));

const StyledSocialButtonBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme?.padding?.paddingX?.[0] ?? 0,
  width: "100%",
}));

const StyledEmailIcon = styled(EmailIcon)({
  height: "18px",
});

const StyledLocalPhoneIcon = styled(LocalPhoneIcon)({
  height: "18px",
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.text.white,
}));

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

function AppFooter() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const emailAddress = "support@yolo.my";
  const phoneNumber = "+6014-9291597";
  const address =
    "Unit 102, Block A4, Leisure Commerce Square, No 9, Jalan PJS 8/9, 46150, Petaling Jaya, Selangor.";
  const copyRight = "(C) 2022 YOLO, All Rights Reserved.";
  const facebookLink = "https://www.facebook.com/YOLO.Experiences.MY";
  const instagramLink = "https://www.instagram.com/yolo.experiences/";
  const privacyLink = "http://merchantinfo.yolo.my/privacy-policy/";
  const termsLink = "http://merchantinfo.yolo.my/terms-and-conditions/";
  const refundLink = "https://merchantinfo.yolo.my/payment-and-refund-policies/";

  const renderAddress = () => (
    <StyledLink
      underline="hover"
      target="_blank"
      href="https://www.waze.com/en-GB/live-map/directions/my/selangor/pj/leisure-commerce-square?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJbbuu6NdNzDERxsNZMY5WZ4o"
    >
      <Text type="WhiteColor" component="span">
        {address}
      </Text>
    </StyledLink>
  );

  const renderEmail = () => (
    <StyledLink underline="hover" href={`mailto: ${emailAddress}`}>
      <Icon>
        <StyledEmailIcon />
      </Icon>
      {` ${emailAddress}`}
    </StyledLink>
  );

  const renderPhone = () => (
    <StyledLink
      style={{
        textDecoration: "hover",
        "&:hover": {
          textDecoration: "underline",
        },
      }}
      underline="hover"
      href={`tel: ${phoneNumber}`}
    >
      <Icon>
        <StyledLocalPhoneIcon />
      </Icon>
      {` ${phoneNumber}`}
    </StyledLink>
  );

  const renderTnc = () => (
    <StyledMainBox isMobile={isMobile}>
      <StyledRowBox isMobile={isMobile}>
        <Text type="WhiteColor">{copyRight}</Text>
        <StyledRowBox>
          <Spacer size="s" position="right" />
          <StyledLink href={privacyLink} target="_blank" underline="hover">
            Privacy Policy
          </StyledLink>
          <Spacer size="s" position="right" />
          <Text type="WhiteColor">|</Text>
          <Spacer size="s" position="right" />
          <StyledLink href={termsLink} target="_blank" underline="hover">
            Terms
          </StyledLink>
          <Spacer size="s" position="right" />
          <Text type="WhiteColor">|</Text>
          <Spacer size="s" position="right" />
          <StyledLink href={refundLink} target="_blank" underline="hover">
            Refund Policies
          </StyledLink>
        </StyledRowBox>
      </StyledRowBox>
      <StyledSocialButtonBox isMobile={isMobile}>
        <StyledLink href={facebookLink} target="_blank">
          <IconButton>
            <StyledFacebookIcon />
          </IconButton>
        </StyledLink>
        <StyledLink href={instagramLink} target="_blank">
          <IconButton>
            <StyledInstagramIcon />
          </IconButton>
        </StyledLink>
      </StyledSocialButtonBox>
    </StyledMainBox>
  );

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.colors.brand.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MaxWidthBox>
        <PaddedBox>
          <Grid container spacing={3}>
            <Grid item xs={isMobile ? 12 : 3}>
              <YoloLogo />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Text
                  sx={{
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                  }}
                  variant="h5"
                  type="WhiteColor"
                >
                  Contact Us
                </Text>
                {renderEmail()}
                {renderPhone()}
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Text
                  sx={{
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                  }}
                  variant="h5"
                  type="WhiteColor"
                >
                  Address
                </Text>
                {renderAddress()}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              {renderTnc()}
            </Grid>
          </Grid>
        </PaddedBox>
      </MaxWidthBox>
    </Box>
  );
}

export default AppFooter;

import { Avatar, Box, Grid, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "25%",
  minHeight: "160px",
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
  alignItems: "center",
}));

function MobileDrawerAvatar() {
  const { getProfileObj } = useSelector(profileSelector);

  return (
    <StyledBox>
      <PaddedView multiples={3}>
        <Grid container sx={{ alignItems: "center" }}>
          <Avatar
            sx={{
              width: 75,
              height: 75,
              border: "2px solid white",
            }}
            src={getProfileObj.status === "succeeded" && getProfileObj.data.merchant.imagePath}
          />
          <Spacer position="left" size="m" />
          <Text variant="h6" type="WhiteColor">
            Hi,
            {(getProfileObj.status === "succeeded" && getProfileObj.data.merchant.name) || "there"}!
          </Text>
        </Grid>
      </PaddedView>
    </StyledBox>
  );
}

export default MobileDrawerAvatar;

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";

const FIELDHEIGHT = "45px";
const MENULIST = [
  { label: "Publish", id: "1", value: "publish" },
  { label: "Unlisted", id: "2", value: "unlisted" },
  { label: "In Progress", id: "3", value: "pending" },
];

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.colors.brand.secondary,
      },
    },
  },
  " .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function StatusFilter() {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const [showMenu, setShowMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = values.status.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "status",
        values.status.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("status", values.status.concat(data));
    }
  };

  return (
    <>
      <Button
        disableRipple
        sx={{
          width: "100%",
          textTransform: "none",
          border: `1px solid ${theme.palette.colors.ui.border}`,
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          height: FIELDHEIGHT,
          ":hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleOpenMenu}
      >
        <CenterRowBox>
          <Text sx={{ paddingLeft: "15px", paddingRight: "15px" }}>Status</Text>
          <Spacer size="s" position="left" />
          {showMenu ? (
            <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          )}
        </CenterRowBox>
      </Button>
      <StyledMenu
        sx={{ mt: "40px" }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={showMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(showMenu)}
        onClose={handleCloseMenu}
      >
        <FormControl>
          {MENULIST.map((item) => (
            <MenuItem key={item.id} value={item.label} disableRipple>
              <Checkbox
                disableRipple
                onChange={() => handleCheckboxChange(item)}
                checked={values.status.some((checkedCheckbox) => checkedCheckbox.id === item.id)}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </FormControl>
      </StyledMenu>
    </>
  );
}

export default StatusFilter;

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { FormHelperText, Popper, styled, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  getExperiencesAutoCompleteSuggestion,
  getUsersAutoCompleteSuggestion,
  uniqueLinkSelector,
} from "../../../services/unique-links/unique-link-slice.services";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

function CustomPopper(props) {
  return <Popper {...props} placement="top" />;
}

export default function SpecificListAutoComplete({ name, placeholder, isSpecific, disabled }) {
  const dispatch = useDispatch();
  const { getExperiencesAutoCompleteSuggestionObj } =
    useSelector(uniqueLinkSelector);
  const createSnackBar = useContext(SnackbarContext);
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  useEffect(() => {
      dispatch(getExperiencesAutoCompleteSuggestion("")).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get experience auto complete suggestion! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      });
  }, []);

  const listOnChange = (event, value) => {
    setFieldValue(name, value);
  };

  const getOptions = () => {
    if (name === "experiencesList") {
      if (getExperiencesAutoCompleteSuggestionObj.status === "succeeded") {
        return getExperiencesAutoCompleteSuggestionObj.data;
      }
    }

    return [];
  };

  const onSearchKeywordChange = (keyword) => {
    if (name === "usersList") {
      dispatch(getUsersAutoCompleteSuggestion(keyword)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get user auto complete suggestion! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      });
    } else {
      dispatch(getExperiencesAutoCompleteSuggestion(keyword)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: `Failed to get experience auto complete suggestion! ${error.message}`,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  return (
    <>
      <Autocomplete
        PopperComponent={CustomPopper}
        disabled={disabled || !isSpecific}
        multiple
        onChange={listOnChange}
        options={getOptions()}
        value={isSpecific ? values[name] : []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.option}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            element={StyledTextField}
            placeholder={placeholder}
            variant="outlined"
            onChange={(e) => onSearchKeywordChange(e.target.value)}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

SpecificListAutoComplete.defaultProps = {
  disabled: false,
};

SpecificListAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isSpecific: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

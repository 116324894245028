import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import routes from "../../../navigation/routes";
import {
  profileSelector,
  updateContactNo,
  updateEmail,
  updateLastName,
  updateName,
  updateNickName,
  updatePassword,
  updateWebsite
} from "../../../services/profile/profile-slice.services";
import BankForm from "../profile-form/bank-form.component";
import CompanyWebsiteForm from "../profile-form/company-website-form.component";
import ContactNoForm from "../profile-form/contact-no-form.component";
import EmailForm from "../profile-form/email-form.component";
import FirstNameForm from "../profile-form/first-name-form.component";
import LastNameForm from "../profile-form/list-name-form.component";
import NickNameForm from "../profile-form/nick-name-form.component";
import PasswordForm from "../profile-form/password-form.component";

const firstNameFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().min(2).label("First Name"),
});

const lastNameFormValidationSchema = Yup.object().shape({
  lastName: Yup.string().required().min(2).label("Last Name"),
});

const nickNameFormValidationSchema = Yup.object().shape({
  nickName: Yup.string().required().min(2).label("Nick Name"),
});

const websiteValidationSchema = Yup.object().shape({
  website: Yup.string().label("Website"),
});


const contactNoValidationSchema = Yup.object().shape({
  phone: Yup.string().required().label("Contact Number"),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email Address"),
});

const passwordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required().min(4).label("Current Password"),
  newPassword: Yup.string().required().min(4).label("New Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword"), null], "New password and confirm password must be same.")
    .label("Password confirmation"),
});

function ProfileDetails() {
  const { getProfileObj } = useSelector(profileSelector);
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [nameEdit, setNameEdit] = useState(false);
  const [lastNameEdit, setLastNameEdit] = useState(false);
  const [nickNameEdit, setNickNameEdit] = useState(false);
  const [websiteEdit, setWebsiteEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleNameUpdate = (values) => {
    dispatch(updateName(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setNameEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleLastNameUpdate = (values) => {
    dispatch(updateLastName(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setLastNameEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };


  const handleNickNameUpdate = (values) => {
    dispatch(updateNickName(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setNickNameEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleWebsiteUpdate = (values) => {
    let website = `https://${values.companyWebsite}`;
    website = website === "https://" ? "" : website;
    dispatch(updateWebsite({ website, ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setWebsiteEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleEmailUpdate = (values) => {
    dispatch(updateEmail(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setEmailEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.EMAILVERIFICATION, {
          email: values.email,
          updateEmail: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message:
            error.message === "The given data was invalid."
              ? "The email has already been taken."
              : error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleContactNoUpdate = (values) => {
    dispatch(updateContactNo(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setContactEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handlePasswordUpdate = (values) => {
    dispatch(updatePassword(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsEditing(false);
        setPasswordEdit(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <>
      <Form
        validationSchema={firstNameFormValidationSchema}
        initialValues={{ firstName: getProfileObj.data.creator.firstName }}
        onSubmit={handleNameUpdate}
      >
        <FirstNameForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          nameEdit={nameEdit}
          setNameEdit={setNameEdit}
        />
      </Form>

      <Form
        validationSchema={lastNameFormValidationSchema}
        initialValues={{ lastName: getProfileObj.data.creator.lastName }}
        onSubmit={handleLastNameUpdate}
      >
        <LastNameForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          lastNameEdit={lastNameEdit}
          setLastNameEdit={setLastNameEdit}
        />
      </Form>
      <Form
        validationSchema={nickNameFormValidationSchema}
        initialValues={{
          nickName: getProfileObj.data.creator.nickName
        }}
        onSubmit={handleNickNameUpdate}
      >
        <NickNameForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          nickNameEdit={nickNameEdit}
          setNickNameEdit={setNickNameEdit}
        />
      </Form>
      <Form
        validationSchema={websiteValidationSchema}
        initialValues={{
          website: getProfileObj.data.creator.website?.replace("https://", ""),
        }}
        onSubmit={handleWebsiteUpdate}
      >
        <CompanyWebsiteForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          websiteEdit={websiteEdit}
          setWebsiteEdit={setWebsiteEdit}
        />
      </Form>
      <Form
        validationSchema={contactNoValidationSchema}
        initialValues={{ phone: getProfileObj.data.creator.phone }}
        onSubmit={handleContactNoUpdate}
      >
        <ContactNoForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          contactEdit={contactEdit}
          setContactEdit={setContactEdit}
        />
      </Form>
      <Form
        validationSchema={emailValidationSchema}
        initialValues={{ email: getProfileObj.data.creator.email }}
        onSubmit={handleEmailUpdate}
      >
        <EmailForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          emailEdit={emailEdit}
          setEmailEdit={setEmailEdit}
        />
      </Form>
      <Form
        validationSchema={passwordValidationSchema}
        initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
        onSubmit={handlePasswordUpdate}
      >
        <PasswordForm
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          passwordEdit={passwordEdit}
          setPasswordEdit={setPasswordEdit}
        />
      </Form>

      <BankForm />
    </>
  );
}

export default ProfileDetails;

import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import TextButton from "../../../components/button/text-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function FirstNameForm({ isEditing, setIsEditing, nameEdit, setNameEdit }) {
  const { getProfileObj } = useSelector(profileSelector);
  const { updateNameObj } = useSelector(profileSelector);

  const toggleEdit = () => {
    setNameEdit(true);
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setNameEdit(false);
    setIsEditing(false);
  };

  if (nameEdit) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>First Name</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        <FormFieldText placeholder="First Name" name="firstName" />
        <Spacer size="m" position="top" />
        <FormSubmitButton width="100px" isLoading={updateNameObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>First Name</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={isEditing} />
      </SpaceBetweenBox>
      <Text type="GreyColor">{getProfileObj.data.creator.firstName}</Text>
      <SeperateLine />
    </Grid>
  );
}

FirstNameForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setNameEdit: PropTypes.func.isRequired,
  nameEdit: PropTypes.bool.isRequired,
};

export default FirstNameForm;

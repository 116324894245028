import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import YoloLogo from "../../../../assets/images/yolo_logo";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import routes from "../../../../navigation/routes";
import { login } from "../../../../services/auth/auth-slice.service";
import TopLeftLink from "../../components/top-left-link.component";
import LoginForm from "../components/login-form.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  isRemember: Yup.boolean(),
});

function LoginScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmitLogin = (values) => {
    if (values.isRemember) {
      localStorage.setItem("isRemember", "true");
      localStorage.setItem("creatorEmail", values.email);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("creatorEmail");
    }

    dispatch(login(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (payload.data.creator.emailVerified && payload.data.creator.hasBankDetails) {
          history.push(routes.HOME);
          return;
        }
        if (!payload.data.creator.emailVerified) {
          console.log(routes.EMAILVERIFICATION)
          history.push(routes.EMAILVERIFICATION, {
            email: payload.data.creator.email,
            hasBankDetails: payload.data.creator.hasBankDetails,
            fromLogin: true,
          });
          return;
        }
        if (!payload.data.creator.hasBankDetails) {
          history.push(routes.BUSINESSDETAILS);
          return;
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledMainContainer>
      <TopLeftLink label="< Home" pathname={routes.HOME} />
      <PaddedView multiples={isMobile ? 0 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                email: localStorage.getItem("creatorEmail") || "",
                password: "",
                isRemember: localStorage.getItem("isRemember") === "true",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitLogin}
            >
              <LoginForm />
            </Form>
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default LoginScreen;

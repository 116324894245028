import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import SnackbarProvider from "./components/notification/snackbar.context";
import theme from "./infrastructure/theme";
import GoogleTag from "./infrastructure/utils/google/googleTag";
import HotjarTracking from "./infrastructure/utils/hotjar/hotjarTracking";
import MetaPixel from "./infrastructure/utils/meta/metaPixel";
import store from "./services/store";

// const root = ReactDOM.createRoot(document.getElementById("root"));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    {process.env.REACT_APP_ENVIRONMENT === "production" && <GoogleTag />}
    {process.env.REACT_APP_ENVIRONMENT === "production" && <MetaPixel />}
    {process.env.REACT_APP_ENVIRONMENT === "production" && <HotjarTracking />}
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);


// root.render(
//   <Provider store={store}>
//     {process.env.REACT_APP_ENVIRONMENT === "production" && <GoogleTag />}
//     {process.env.REACT_APP_ENVIRONMENT === "production" && <MetaPixel />}
//     {process.env.REACT_APP_ENVIRONMENT === "production" && <HotjarTracking />}
//     <SnackbarProvider>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <App />
//       </ThemeProvider>
//     </SnackbarProvider>
//   </Provider>,
// );

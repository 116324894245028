import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import TextButton from "../../../components/button/text-button.component";
import FormPhoneNumber from "../../../components/forms/form-phone-number.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function ContactNoForm({ isEditing, setIsEditing, contactEdit, setContactEdit }) {
  const { getProfileObj, updateContactNoObj } = useSelector(profileSelector);

  const toggleEdit = () => {
    setContactEdit(true);
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setContactEdit(false);
    setIsEditing(false);
  };

  if (contactEdit) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Contact Number</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        <FormPhoneNumber name="phone" placeholder="Contact Number" />
        <Spacer size="m" position="top" />
        <FormSubmitButton width="100px" isLoading={updateContactNoObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Contact Number</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={isEditing} />
      </SpaceBetweenBox>
      <Text type="GreyColor">{getProfileObj.data.creator.phone}</Text>
      <SeperateLine />
    </Grid>
  );
}

ContactNoForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  contactEdit: PropTypes.bool.isRequired,
  setContactEdit: PropTypes.func.isRequired,
};

export default ContactNoForm;

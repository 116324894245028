import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import YoloLogo from "../../../../assets/images/yolo_logo";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import routes from "../../../../navigation/routes";
import {
  logout,
  putBusinessDetails,
  resetUser,
} from "../../../../services/auth/auth-slice.service";
import { getProfile, profileSelector } from "../../../../services/profile/profile-slice.services";
import BusinessDetailsForm from "../components/business-details-form.component";
import BusinessFormLoader from "../loader/business-form-loader.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  // email: Yup.string().required().email().label("Email"),
  // firstName: Yup.string().required().label("FirstName"),
  // lastName: Yup.string().required().label("LastName"),
  // nickName: Yup.string().optional().label("NickName"),
  // gender: Yup.string().required().label("Gender"),
  // phone: Yup.string()
  //     .nullable()
  //     .required()
  //     .when({
  //         is: (value) => value?.length,
  //         then: (rule) => rule.min(10),
  //     })
  //     .label("Phone"),
  bankAccountNo: Yup.number().required().label("Account Number"),
  bankName: Yup.string().required().label("Bank Name"),
  accHolderName: Yup.string().required().label("Account Holder Name"),
  // commission: Yup.number().min(0).max(100).required().label("Commission Rate"),
});


function BusinessDetailScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const { getProfileObj } = useSelector(profileSelector);

  useEffect(() => {
    localStorage.setItem("backToScreen", "businessDetails");
    dispatch(getProfile()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handleSubmitDetails = (values) => {
    const bank = {};
    bank.bankAccountNo = values.bankAccountNo;
    bank.bankName = values.bankName;
    bank.accountHolderName = values.accHolderName;
    const website = values.companyWebsite !== "" ? `https://${values.companyWebsite}` : "";

    dispatch(putBusinessDetails({ bank, website, ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        localStorage.removeItem("backToScreen");
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.HOME);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  return (
    <StyledMainContainer>
      <PaddedView multiples={isMobile ? 2 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo isDisable={true} />
          </Grid>
          <Grid item xs={12}>
            {getProfileObj.status === "succeeded" ? (
              <Form
                initialValues={{
                  // firstName:
                  //   (getProfileObj.status === "succeeded" && getProfileObj.data.creator.firstName) ||
                  //   "",
                  // lastName:
                  //   (getProfileObj.status === "succeeded" && getProfileObj.data.creator.lastName) ||
                  //   "",
                  // nickName:
                  //   (getProfileObj.status === "succeeded" && getProfileObj.data.creator.nickName) ||
                  //   "",
                  // email:
                  //   (getProfileObj.status === "succeeded" && getProfileObj.data.creator.email) ||
                  //   "",
                  // website:
                  //   getProfileObj.data.creator.website?.replace("https://", "") || "",
                  // phone: getProfileObj.data.creator.phone || "",
                  bankAccountNo: getProfileObj.data.bankDetails.bankAccountNo || "",
                  bankName: getProfileObj.data.bankDetails.bankName || "",
                  accHolderName: getProfileObj.data.bankDetails.name || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitDetails}
              >
                <BusinessDetailsForm handleLogout={handleLogout} />
              </Form>
            ) : (
              <BusinessFormLoader />
            )}
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default BusinessDetailScreen;

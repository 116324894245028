import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq, putReq, setAxiosToken } from "../api.services";

export const login = createAsyncThunk("auth/creators/login", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/login`,
        payload,
    );
    return response;
});

export const register = createAsyncThunk("auth/creators/register", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/register`,
        payload,
    );
    return response;
});

export const emailCheck = createAsyncThunk("auth/creators/check_email", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/check_email`,
        payload,
    );
    return response;
});

export const postVerificationCode = createAsyncThunk(
    "auth/creators/post_verification_code",
    async (payload) => {
        const response = await postReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/email_verification`,
            payload,
        );
        return response;
    },
);

export const putBusinessDetails = createAsyncThunk(
    "auth/creators/put_bank_details",
    async (payload) => {
        const response = await putReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/bank_details`,
            payload,
        );
        return response;
    },
);

export const resendVerificationCode = createAsyncThunk(
    "auth/creators/resend_verification_code",
    async (payload) => {
        const response = await postReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/resend_verification_code`,
            payload,
        );
        return response;
    },
);

export const forgotPassword = createAsyncThunk(
    "auth/creators/forgot_password",
    async (payload) => {
        const response = await postReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/forgot_password`,
            payload,
        );
        return response;
    },
);

export const resetPassword = createAsyncThunk("auth/creators/reset_password", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/reset_password`,
        payload,
    );
    return response;
});

export const logout = createAsyncThunk("auth/creators/logout", async () => {
    const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/creators/logout`);
    return response;
});

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        loginObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        emailCheckObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        registerObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        postVerificationCodeObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        putBusinessDetailsObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        resendVerificationCodeObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        forgotPasswordObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        resetPasswordObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        logoutObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
    },
    reducers: {
        resetUser: (state) => {
            state.user = null;
            state.loginObj.data = null;
            setAxiosToken(null);
            localStorage.removeItem("yoloCreatorAuthToken");
        },
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loginObj.status = "pending";
        },
        [login.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            localStorage.setItem("yoloCreatorAuthToken", data.authToken);
            setAxiosToken(data.authToken);
            state.loginObj.status = "succeeded";
            state.loginObj.data = data;
            state.loginObj.successMessage = message;
            state.user = data;
        },
        [login.rejected]: (state, action) => {
            const { message } = action.error;

            state.loginObj.status = "failed";
            state.loginObj.errorMessage = message;
        },
        [register.pending]: (state) => {
            state.registerObj.status = "pending";
        },
        [register.fulfilled]: (state, action) => {
            const { data, message } = action.payload;
            localStorage.setItem("yoloCreatorAuthToken", data.authToken);
            setAxiosToken(data.authToken);
            state.registerObj.status = "succeeded";
            state.registerObj.data = data;
            state.registerObj.successMessage = message;
            state.user = data;
        },
        [register.rejected]: (state, action) => {
            const { message } = action.error;

            state.registerObj.status = "failed";
            state.registerObj.errorMessage = message;
        },
        [emailCheck.pending]: (state) => {
            state.emailCheckObj.status = "pending";
        },
        [emailCheck.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.emailCheckObj.status = "succeeded";
            state.emailCheckObj.data = data;
            state.emailCheckObj.successMessage = message;
        },
        [emailCheck.rejected]: (state, action) => {
            const { message } = action.error;

            state.emailCheckObj.status = "failed";
            state.emailCheckObj.errorMessage = message;
        },
        [postVerificationCode.pending]: (state) => {
            state.postVerificationCodeObj.status = "pending";
        },
        [postVerificationCode.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.postVerificationCodeObj.status = "succeeded";
            state.postVerificationCodeObj.data = data;
            state.postVerificationCodeObj.successMessage = message;
        },
        [postVerificationCode.rejected]: (state, action) => {
            const { message } = action.error;

            state.postVerificationCodeObj.status = "failed";
            state.postVerificationCodeObj.errorMessage = message;
        },
        [putBusinessDetails.pending]: (state) => {
            state.putBusinessDetailsObj.status = "pending";
        },
        [putBusinessDetails.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.putBusinessDetailsObj.status = "succeeded";
            state.putBusinessDetailsObj.data = data;
            state.putBusinessDetailsObj.successMessage = message;
        },
        [putBusinessDetails.rejected]: (state, action) => {
            const { message } = action.error;

            state.putBusinessDetailsObj.status = "failed";
            state.putBusinessDetailsObj.errorMessage = message;
        },
        [resendVerificationCode.pending]: (state) => {
            state.resendVerificationCodeObj.status = "pending";
        },
        [resendVerificationCode.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.resendVerificationCodeObj.status = "succeeded";
            state.resendVerificationCodeObj.data = data;
            state.resendVerificationCodeObj.successMessage = message;
        },
        [resendVerificationCode.rejected]: (state, action) => {
            const { message } = action.error;

            state.resendVerificationCodeObj.status = "failed";
            state.resendVerificationCodeObj.errorMessage = message;
        },
        [forgotPassword.pending]: (state) => {
            state.forgotPasswordObj.status = "pending";
        },
        [forgotPassword.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.forgotPasswordObj.status = "succeeded";
            state.forgotPasswordObj.data = data;
            state.forgotPasswordObj.successMessage = message;
        },
        [forgotPassword.rejected]: (state, action) => {
            const { message } = action.error;

            state.forgotPasswordObj.status = "failed";
            state.forgotPasswordObj.errorMessage = message;
        },
        [resetPassword.pending]: (state) => {
            state.resetPasswordObj.status = "pending";
        },
        [resetPassword.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.resetPasswordObj.status = "succeeded";
            state.resetPasswordObj.data = data;
            state.resetPasswordObj.successMessage = message;
        },
        [resetPassword.rejected]: (state, action) => {
            const { message } = action.error;

            state.resetPasswordObj.status = "failed";
            state.resetPasswordObj.errorMessage = message;
        },
        [logout.pending]: (state) => {
            state.logoutObj.status = "pending";
        },
        [logout.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.logoutObj.status = "succeeded";
            state.logoutObj.data = data;
            state.logoutObj.successMessage = message;

            state.user = null;
            state.loginObj.data = null;
            setAxiosToken(null);
            localStorage.removeItem("yoloCreatorAuthToken");
            localStorage.removeItem("backToScreen");
            localStorage.removeItem("creatorEmail");
        },
        [logout.rejected]: (state, action) => {
            const { message } = action.error;

            state.logoutObj.status = "failed";
            state.logoutObj.errorMessage = message;
        },
    },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;

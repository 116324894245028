import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import CtaButton from "../../../../components/button/cta-button.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import SearchBar from "../search-bar.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.colors.bg.white,
  },
}));

const StyledTopNavBar = styled(Box)(({ theme }) => ({
  height: "80px",
  background: theme.palette.colors.bg.white,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
  position: "fixed",
  zIndex: 1,
}));

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  left: "20px",
}));

const StyledBottomNavBar = styled(Box)(({ theme }) => ({
  paddingLeft: theme?.padding?.paddingX?.[2] ?? 2,
  paddingRight: theme?.padding?.paddingX?.[2] ?? 2,
  height: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
  background: theme.palette.colors.bg.white,
}));

const ClearFilterButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    textTransform: "none",
    textDecoration: "underline",
    padding: "0px",
    textDecorationColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
}));

const ScrollBox = styled(Box)(({ theme }) => ({
  paddingTop: "120px",
  paddingBottom: "120px",
  paddingLeft: theme?.padding?.paddingX?.[1] ?? 1,
  paddingRight: theme?.padding?.paddingX?.[1] ?? 1,
  " .MuiListItemText-root": {
    color: theme.palette.colors.text.secondary,
  },
  " .MuiMenuItem-root": {
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiMenuItem-root": {
    "& .MuiSvgIcon-root": {
      fontSize: 20,
      color: theme.palette.colors.brand.secondary,
    },
  },
}));

const STATUSLIST = [
  { label: "Publish", id: "1", value: "publish" },
  { label: "Unlisted", id: "2", value: "unlisted" },
  { label: "In Progress", id: "3", value: "pending" },
];

const STATESLIST = [
  { label: "Johor", id: "1", value: "johor" },
  { label: "Kedah", id: "2", value: "kedah" },
  { label: "Kelantan", id: "3", value: "kelantan" },
  { label: "Kuala Lumpur	", id: "4", value: "kuala lumpur" },
  { label: "Melaka", id: "5", value: "melaka" },
  { label: "Negeri Sembilan", id: "6", value: "negeri sembilan" },
  { label: "Pahang", id: "7", value: "pahang" },
  { label: "Penang", id: "8", value: "penang" },
  { label: "Perak	", id: "9", value: "perak" },
  { label: "Perlis	", id: "10", value: "perlis" },
  { label: "Putrajaya	", id: "11", value: "putrajaya" },
  { label: "Sabah	", id: "12", value: "sabah" },
  { label: "Sarawak	", id: "13", value: "sarawak" },
  { label: "Selangor		", id: "14", value: "selangor" },
  { label: "Terengganu	", id: "15", value: "terengganu" },
  { label: "Labuan	", id: "16", value: "labuan" },
];

const PACKAGELIST = [
  { label: "Individual", id: "1", value: "person" },
  { label: "Group", id: "2", value: "group" },
];

function FilterDrawer({ openDrawer, setOpenDrawer }) {
  const { values, setFieldValue, resetForm, handleSubmit } = useFormikContext();
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleStatushange = (data) => {
    const isChecked = values.status.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "status",
        values.status.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("status", values.status.concat(data));
    }
  };

  const handleStatesChange = (data) => {
    const isChecked = values.states.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "states",
        values.states.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("states", values.states.concat(data));
    }
  };

  const handlePackageChange = (data) => {
    const isChecked = values.type.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "type",
        values.type.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("type", values.type.concat(data));
    }
  };

  return (
    <StyledDrawer anchor="right" variant="temporary" open={openDrawer} onClose={handleCloseDrawer}>
      <StyledTopNavBar sx={{ boxShadow: 3 }}>
        <StyledBackButton onClick={handleCloseDrawer}>
          <ArrowBackIosIcon />
        </StyledBackButton>
        <Typography variant="h5">Filters</Typography>
      </StyledTopNavBar>
      <ScrollBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SearchBar name="q" placeholder="Search" />
          </Grid>
          <Grid item xs={12}>
            <Text>Status</Text>
            <Spacer size="s" position="top" />
            <FormControl>
              {STATUSLIST.map((item) => (
                <MenuItem key={item.id} value={item.label} disableRipple>
                  <Checkbox
                    onChange={() => handleStatushange(item)}
                    checked={values.status.some(
                      (checkedCheckbox) => checkedCheckbox.id === item.id,
                    )}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Text>States</Text>
            <Spacer size="s" position="top" />
            <FormControl>
              {STATESLIST.map((item) => (
                <MenuItem key={item.id} value={item.label} disableRipple>
                  <Checkbox
                    onChange={() => handleStatesChange(item)}
                    checked={values.states.some(
                      (checkedCheckbox) => checkedCheckbox.id === item.id,
                    )}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Text>Type Of Packages</Text>
            <Spacer size="s" position="top" />
            <FormControl>
              {PACKAGELIST.map((item) => (
                <MenuItem key={item.id} value={item.label} disableRipple>
                  <Checkbox
                    onChange={() => handlePackageChange(item)}
                    checked={values.type.some((checkedCheckbox) => checkedCheckbox.id === item.id)}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </FormControl>
          </Grid>
        </Grid>
      </ScrollBox>

      <StyledBottomNavBar sx={{ boxShadow: 3 }}>
        <ClearFilterButtonBox
          onClick={() => {
            resetForm();
            handleSubmit();
            handleCloseDrawer();
          }}
        >
          <Button disableRipple>
            <Text type="BrandColor">Clear All</Text>
          </Button>
        </ClearFilterButtonBox>

        <CtaButton
          onClickButton={() => {
            handleSubmit();
            handleCloseDrawer();
          }}
        >
          <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
            Apply
          </Text>
        </CtaButton>
      </StyledBottomNavBar>
    </StyledDrawer>
  );
}

FilterDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
};

export default FilterDrawer;

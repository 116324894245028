import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Box, Grid, IconButton, Pagination, styled, Table,
    TableBody,
    TableCell,
    TableRow,
    useMediaQuery, useTheme
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import Form from "../../../components/forms/form.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import Loading from "../../../components/notification/loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import {
    createCreatorSocial,
    deleteCreatorSocial,
    getCreatorSocialList,
    uniqueLinkSelector,
} from "../../../services/unique-links/unique-link-slice.services";
import SearchBar from "../../experiences/components/search-bar.component";
import CreatorSocialCreateModal from "../components/creator-social-create-modal.component";
import SocialListRow from "../components/social-row.component";
import SocialMediaUnableDeleteModal from "../components/social-unable-delete-modal.component";

const SpaceBetweenRowBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
});

const CustomFooter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minHeight: "70px",
    alignItems: "center",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: "0px",
    color: theme.palette.colors.brand.primary,
}));

const StyledFilterIcon = styled(FilterListIcon)(({ theme }) => ({
    color: theme.palette.colors.brand.secondary,
    fontSize: "27px",
}));

const StyledAddIcon = styled(AddCircleOutlinedIcon)({
    fontSize: "27px",
});

const validationSchema = Yup.object().shape({
    q: Yup.string().label("Search"),
    status: Yup.array().label("Status"),
    states: Yup.array().label("State"),
    type: Yup.array().label("Type"),
});

const CenterBox = styled(Box)({ display: "flex", justifyContent: "center", alignItems: "center" });

function SocialMediaScreen() {
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const isMobile = useMediaQuery(theme.breakpoints.down("800"));
    const isShowThreeItems = useMediaQuery(theme.breakpoints.down("1200"));
    const isShowTwoItems = useMediaQuery(theme.breakpoints.down("960"));
    const [openDrawer, setOpenDrawer] = useState(false);
    const createSnackBar = useContext(SnackbarContext);
    const { getCreatorSocialListObj, deleteCreatorSocialObj } = useSelector(uniqueLinkSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [deleteLinkId, setDeleteLinkId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [currentParams, setCurrentParams] = useState(null);
    const { getProfileObj } = useSelector(profileSelector);

    const getItemToShow = () => {
        if (isMobile) {
            return 12;
        }
        if (isShowTwoItems) {
            return 6;
        }
        if (isShowThreeItems) {
            return 4;
        }

        return 3;
    };


    useEffect(() => {
        dispatch(getCreatorSocialList({ q: searchKeyword, page })).then(
            ({ meta, error }) => {
                if (meta.requestStatus === "rejected") {
                    createSnackBar({
                        message: error.message,
                        type: "error",
                    });
                }
            },
        );
    }, []);

    const onSearchSocialMedia = (values) => {
        setPage(1);
        dispatch(
            getCreatorSocialList({
                ...values,
                page: 1,
            }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };


    const resetSearch = () => {
        dispatch(getCreatorSocialList({ q: "", page: 1 })).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
        setSearchKeyword("");
        setPage(1);
    };

    const onSocialMediaCreate = (value, { resetForm }) => {
        const params = {
            social_type: value.social_type,
            social_id: value.social_id
        };
        setIsLoading(true);
        dispatch(createCreatorSocial({ ...params })).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowCreateModal(false);
                resetForm();
                resetSearch();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            }
            if (meta.requestStatus === "rejected") {
                console.log(error.message)
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };

    const onPageChange = (e, newPage) => {
        dispatch(
            getCreatorSocialList({ ...formRef.current.values, page: newPage }),
        ).then(({ meta, error }) => {
            if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                    open: true,
                });
            }
        });
        setPage(newPage);
    };


    const onTriggerDelete = (deleteable, linkId) => {
        if (deleteable) {
            setDeleteLinkId(linkId);
            setShowDeleteModal(true);
            return;
        }
        setShowUnableDeleteModal(true);
    };


    const onConfirmDelete = () => {
        setIsLoading(true);
        dispatch(deleteCreatorSocial(deleteLinkId)).then(({ meta, error, payload }) => {
            setIsLoading(false);
            if (meta.requestStatus === "fulfilled") {
                setShowDeleteModal(false);
                setDeleteLinkId(null);
                resetSearch();
                createSnackBar({
                    message: payload.message,
                    type: "success",
                });
            } else if (meta.requestStatus === "rejected") {
                createSnackBar({
                    message: error.message,
                    type: "error",
                });
            }
        });
    };



    const renderContent = () => {
        if (getCreatorSocialListObj.status === "succeeded") {
            return (
                <Box sx={{ padding: theme?.padding?.paddingX?.[2] ?? 2, width: "100%" }}>
                    <>
                    <Table>
                        <TableHeader headerCells={["ID", "Social Media", "Social ID", "Status", ""]} />
                        <TableBody>
                            {getCreatorSocialListObj.data.items.map((item) => (
                                <SocialListRow record={item} key={item.id} onTriggerDelete={onTriggerDelete}/>
                            ))}
                        </TableBody>
                    </Table>
                    <CustomFooter>
                        <Pagination
                            sx={{
                                "&& .Mui-selected": {
                                    backgroundColor: theme.palette.colors.brand.primary,
                                    color: theme.palette.colors.text.white,
                                },
                            }}
                            page={page}
                            onChange={onPageChange}
                            count={getCreatorSocialListObj.data.pagination.totalPages}
                            variant="outlined"
                        />
                    </CustomFooter>
                </>
                </Box>
            );
        }
        return (
            <Table>
                <TableHeader headerCells={["ID", "Social Media", "Social ID", "Status", ""]} />
                <TableBody>
                    <TableRow
                        sx={{
                            "& .MuiTableCell-root": {
                                borderBottom: "no-set",
                            },
                        }}
                    >
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body2" sx={{ textAlign: "center" }}>
                                <Skeleton />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                                <Grid item>
                                    <Skeleton height={20} width={20} circle={true} />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (

        <Box sx={{ padding: theme?.padding?.paddingX?.[2] ?? 2, width: "100%" }}>
            <SocialMediaUnableDeleteModal
                showModal={showUnableDeleteModal}
                setShowModal={setShowUnableDeleteModal}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                label="Are you sure to delete this Social Media?"
                isLoading={deleteCreatorSocialObj.status === "pending"}
                onConfirmClicked={onConfirmDelete}
            />
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Text
                            variant={isMobile ? "h5" : "h4"}
                            sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                        >
                            Social Media List
                        </Text>
                    </Grid>
                    <Loading isLoading={isLoading} />
                    <Grid item xs={2}>
                        <Form
                            onSubmit={onSocialMediaCreate}
                            validationSchema={validationSchema}
                            initialValues={{
                                experienceID: null,
                            }}
                        >
                            <CreatorSocialCreateModal isShowModal={showCreateModal} setIsShowModal={setShowCreateModal} />
                            <CtaButton width="125px" onClickButton={setShowCreateModal}>
                                <Text type="WhiteColor">Create</Text>
                            </CtaButton>
                        </Form>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Form
                            innerRef={formRef}
                            onSubmit={onSearchSocialMedia}
                            validationSchema={validationSchema}
                            initialValues={{
                                q: "",
                            }}
                        >
                            <SearchBar name="q" placeholder="SocialMedia ID" width="100%" />
                        </Form>
                    </Grid>
                </Grid>
                {renderContent()}
            </Grid>
        </Box>
    );


    // return (
    //     <Form
    //         // onSubmit={handleSubmit}
    //         initialValues={{ q: "", status: [], states: [], type: [] }}
    //         validationSchema={validationSchema}
    //     >
    //         <FilterDrawer setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
    //         <Box sx={{ padding: theme?.padding?.paddingX?.[0] ?? 0, width: "100%" }}>
    //             <Grid container spacing={5}>
    //                 <Grid item xs={12}>
    //                     <SpaceBetweenRowBox>
    //                         <Text
    //                             variant={isMobile ? "h5" : "h4"}
    //                             sx={{ fontWeight: theme.fonts.fontWeights.bold }}
    //                         >
    //                             Experiences
    //                         </Text>
    //                         {/* {isMobile ? (
    //             <Box sx={{ display: "flex", flexDirection: "row" }}>
    //               <StyledIconButton onClick={() => setOpenDrawer(true)}>
    //                 <StyledFilterIcon />
    //               </StyledIconButton>
    //               <Spacer size="m" position="left" />
    //               <StyledIconButton onClick={() => history.push(routes.CREATEEXPERIENCES)} disabled>
    //                 <StyledAddIcon />
    //               </StyledIconButton>
    //             </Box>
    //           ) : (
    //             <CtaButton onClickButton={onCreateButtonClick} disabled>
    //               <Text type="WhiteColor">Create Experiences</Text>
    //             </CtaButton>
    //           )} */}
    //                     </SpaceBetweenRowBox>
    //                 </Grid>

    //                 {!isMobile && (
    //                     <Grid item xs={12}>
    //                         <Grid container spacing={1} justifyContent="space-between">
    //                             <Grid item width="35%">
    //                                 <SearchBar name="q" placeholder="Search Experiences" />
    //                             </Grid>
    //                             <Grid item width="15%">
    //                                 <StatusFilter />
    //                             </Grid>
    //                             <Grid item width="15%">
    //                                 <StatesFilter />
    //                             </Grid>
    //                             <Grid item width="20%">
    //                                 <PackageFilter />
    //                             </Grid>
    //                             <Grid item width="15%">
    //                                 <FilterButtonContainer />
    //                             </Grid>
    //                         </Grid>
    //                     </Grid>
    //                 )}
    //                 {/* {renderExperiencesList()} */}
    //             </Grid>
    //         </Box>
    //     </Form>
    // );
}

export default SocialMediaScreen;
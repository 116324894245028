export default Object.freeze({
  LOGIN: "/login",
  SIGNUP: "/sign_up",
  BUSINESSDETAILS: "/business_details",
  EMAILVERIFICATION: "/email_verification",
  FORGOTPASSWORD: "/forgot_password",
  RESETPASSWORD: "/reset_password",

  PROFILE: "/profile",
  ACCOUNTMENU: "/account_menu",
  SALESTRACKING: "/sales",
  STATEMENT_DETAILS: "/sales/details",

  UNIQUELINKS: "/uniqueLinks",
  SOCIALMEDIA: "/social",

  CREATEEXPERIENCES: "/experiences/create",
  SELECT_CATEGORY: "/experiences/create/category",
  SELECT_LOCATION: "/experiences/create/location",
  TITILE_FIELD: "/experiences/create/title",
  UPLOAD_IMAGES: "/experiences/create/upload_images",
  DESCRIPTION_FIELD: "/experiences/create/description",
  IMPORTANT_INFO_FIELD: "/experiences/create/important_info",
  SET_PAX: "/experiences/create/pax",
  CREATE_AVAILABLE_SLOT: "/experiences/create/slot",
  CHECK_OUT_PREVIEW: "/experiences/create/preview",
  PUBLISH_EXPERIENCE: "/experiences/create/publish",

  EXPERIENCES: "/experiences",
  EDIT_EXPERIENCES: "/experiences/edit",

  BOOKINGS: "/bookings",

  CALENDAR: "/calendar",

  HOME: "/",
});

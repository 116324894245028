import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import YoloLogo from "../../../../assets/images/yolo_logo";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import routes from "../../../../navigation/routes";
import {
  resendVerificationCode,
  resetPassword,
} from "../../../../services/auth/auth-slice.service";
import TopLeftLink from "../../components/top-left-link.component";
import ResetPasswordForm from "../components/reset-password-form.component";
import ResetPasswordSuccessful from "../components/reset-password-successful.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  verificationCode: Yup.string().required().label("Verification Code"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

function ResetPasswordScreen(props) {
  const newProps = props;
  const userEmail = newProps.history.location.state.email;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleResendCode = () => {
    const values = {};
    values.email = userEmail;
    values.type = "forgotPassword";
    dispatch(resendVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitForm = (values) => {
    dispatch(resetPassword(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setResetSuccess(true);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledMainContainer>
      {!resetSuccess && <TopLeftLink label="< Back" pathname={routes.FORGOTPASSWORD} />}
      <PaddedView multiples={isMobile ? 2 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                email: userEmail,
                verificationCode: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {resetSuccess ? (
                <ResetPasswordSuccessful />
              ) : (
                <ResetPasswordForm handleResendCode={handleResendCode} />
              )}
            </Form>
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default ResetPasswordScreen;

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Grid, IconButton, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  experienceSelector,
  searchExperiences,
} from "../../../services/experience/experience-slice.services";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import ExperienceCard from "../components/experience-card.component";
import FilterDrawer from "../components/mobile-view/filter-drawer.component";
import FilterButtonContainer from "../components/pc-view/filter-button-container.component";
import PackageFilter from "../components/pc-view/package-filter.component";
import StatesFilter from "../components/pc-view/states-filter.component";
import StatusFilter from "../components/pc-view/status-filter.component";
import SearchBar from "../components/search-bar.component";

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.brand.primary,
}));

const StyledFilterIcon = styled(FilterListIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
  fontSize: "27px",
}));

const StyledAddIcon = styled(AddCircleOutlinedIcon)({
  fontSize: "27px",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().label("Search"),
  status: Yup.array().label("Status"),
  states: Yup.array().label("State"),
  type: Yup.array().label("Type"),
});

const CenterBox = styled(Box)({ display: "flex", justifyContent: "center", alignItems: "center" });

function ExperienceScreen() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const isShowThreeItems = useMediaQuery(theme.breakpoints.down("1200"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("960"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const { searchExperiencesObj } = useSelector(experienceSelector);
  const [page, setPage] = useState(1);
  const [currentParams, setCurrentParams] = useState(null);
  const { getProfileObj } = useSelector(profileSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    if (isShowThreeItems) {
      return 4;
    }

    return 3;
  };

  const handleSubmit = (values) => {
    const modifiedValues = { status: [], states: [], type: [], q: values.q };
    values.status.map((item) => modifiedValues.status.push(item.value));
    values.states.map((item) => modifiedValues.states.push(item.value));
    values.type.map((item) => modifiedValues.type.push(item.value));
    modifiedValues.status = modifiedValues.status.toString();
    modifiedValues.states = modifiedValues.states.toString();
    modifiedValues.type = modifiedValues.type.toString();
    setCurrentParams(modifiedValues);
    setPage(1);
    dispatch(searchExperiences({ page: 1, ...modifiedValues })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(searchExperiences({ page: value, ...currentParams })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(searchExperiences({ page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onCreateButtonClick = () => {
    if (getProfileObj.data.merchant.status === "approved") {
      history.push(routes.CREATEEXPERIENCES);
      return;
    }
    createSnackBar({
      message: "Your account is being verified. Please come back later.",
      type: "error",
      open: true,
    });
  };

  const renderExperiencesList = () => {
    if (searchExperiencesObj.status === "succeeded") {
      if (searchExperiencesObj.data.experiences.items.length <= 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xxxl" position="top" />
            <CenterBox>
              <Text>No Items</Text>
            </CenterBox>
          </Grid>
        );
      }
      return (
        <>
          <Grid item xs={12}>
            <Grid container rowSpacing={4} spacing={3}>
              {searchExperiencesObj.data.experiences.items.map((items) => (
                <Grid item key={items.id} xs={getItemToShow()}>
                  {/* <ExperiencesItem
                    // onToggleFavourite={onToggleFavourite}
                    data={items}
                    width="100%"
                    experienceId={items.id}
                  /> */}
                  <ExperienceCard cardDetails={items} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {searchExperiencesObj.data.experiences.pagination.totalPages >= 2 && (
            <Grid item xs={12}>
              <Grid container justifyContent="center" paddingTop="50px">
                <Pagination
                  page={page}
                  onChange={handlePageChange}
                  count={searchExperiencesObj.data.experiences.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Spacer size="xxxl" position="top" />
        <CenterBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </CenterBox>
      </Grid>
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ q: "", status: [], states: [], type: [] }}
      validationSchema={validationSchema}
    >
      <FilterDrawer setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
      <Box sx={{ padding: theme?.padding?.paddingX?.[0] ?? 0, width: "100%" }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SpaceBetweenRowBox>
              <Text
                variant={isMobile ? "h5" : "h4"}
                sx={{ fontWeight: theme.fonts.fontWeights.bold }}
              >
                Experiences
              </Text>
              {/* {isMobile ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <StyledIconButton onClick={() => setOpenDrawer(true)}>
                    <StyledFilterIcon />
                  </StyledIconButton>
                  <Spacer size="m" position="left" />
                  <StyledIconButton onClick={() => history.push(routes.CREATEEXPERIENCES)} disabled>
                    <StyledAddIcon />
                  </StyledIconButton>
                </Box>
              ) : (
                <CtaButton onClickButton={onCreateButtonClick} disabled>
                  <Text type="WhiteColor">Create Experiences</Text>
                </CtaButton>
              )} */}
            </SpaceBetweenRowBox>
          </Grid>

          {!isMobile && (
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item width="35%">
                  <SearchBar name="q" placeholder="Search Experiences" />
                </Grid>
                <Grid item width="15%">
                  <StatusFilter />
                </Grid>
                <Grid item width="15%">
                  <StatesFilter />
                </Grid>
                <Grid item width="20%">
                  <PackageFilter />
                </Grid>
                <Grid item width="15%">
                  <FilterButtonContainer />
                </Grid>
              </Grid>
            </Grid>
          )}
          {renderExperiencesList()}
        </Grid>
      </Box>
    </Form>
  );
}

export default ExperienceScreen;

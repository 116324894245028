import { Avatar, Box, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../../navigation/routes";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import { isLogin } from "../../utils";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const StyledAvatarButton = styled(IconButton)({
  ":hover": { backgroundColor: "transparent" },
  ":active": { backgroundColor: "transparent" },
  marginTop: "5px",
});

function AvatarMenu({ handleLogout }) {
  const [avatarMenu, setAvatarMenu] = useState(null);
  const history = useHistory();
  const { getProfileObj } = useSelector(profileSelector);
  const handleOpenUserMenu = (event) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };

  return (
    <Box>
      <StyledAvatarButton onClick={handleOpenUserMenu}>
        {getProfileObj.status === "succeeded" ? (
          <Avatar
            sx={{ width: "40px", height: "40px" }}
            src={getProfileObj.data.creator.imagePath}
          />
        ) : (
          <Avatar sx={{ width: "40px", height: "40px" }} />
        )}
      </StyledAvatarButton>
      <StyledMenu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={avatarMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(avatarMenu)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={1}
          onClick={() => {
            handleCloseUserMenu();
            history.push(routes.PROFILE);
          }}
        >
          <Typography textAlign="center">Account</Typography>
        </MenuItem>
        <MenuItem key={2} onClick={() => handleCloseUserMenu()}>
          <Typography textAlign="center">Help and Support</Typography>
        </MenuItem>
        {isLogin() && (
          <MenuItem key={3} onClick={handleLogout}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        )}
      </StyledMenu>
    </Box>
  );
}

AvatarMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default AvatarMenu;

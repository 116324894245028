import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";

export const getUniqueLinks = createAsyncThunk("/creators/unique-links", async (payload) => {
    const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/creators/unique-links?q=${payload.q}&page=${payload.page}`);
    return response;
});

export const createCreatorUniqueLinks = createAsyncThunk("creators/uniqueLinks", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/unique-links`,
        payload,
    );
    return response;
});

export const deleteCreatorUniqueLink = createAsyncThunk("/creators/uniqueLinks-delete", async (linkId) => {
    const response = await delReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/unique-links/${linkId}`,
    );
    return response;
});

export const getExperiencesAutoCompleteSuggestion = createAsyncThunk(
    "creators/experiences/auto_complete",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/unique-links/experiences_auto_complete?q=${payload}`,
        );
        return response;
    },
);

export const getUsersAutoCompleteSuggestion = createAsyncThunk(
    "creators/users/auto_complete",
    async (payload) => {
        const response = await getReq(
            `${process.env.REACT_APP_API_PRIVATE_V1}/creators/unique-links/users_auto_complete?q=${payload}`,
        );
        return response;
    },
);

export const getCreatorSocialList = createAsyncThunk("/creators/socials", async (payload) => {
    const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/creators/socials?q=${payload.q}&page=${payload.page}`);
    return response;
});

export const createCreatorSocial = createAsyncThunk("creators/socials-create", async (payload) => {
    const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/socials`,
        payload,
    );
    return response;
});

export const updateCreatorSocial = createAsyncThunk("creators/socials-update", async (payload) => {
    const response = await putReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/socials/${payload.socialId}?params=${payload.type}`,
        payload,
    );
    return response;
});

export const deleteCreatorSocial = createAsyncThunk("/creators/social-delete", async (linkId) => {
    const response = await delReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/creators/socials/${linkId}`,
    );
    return response;
});


const uniqueLinkSlice = createSlice({
    name: "uniqueLink",
    initialState: {
        getCreatorLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCreatorLinksObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        deleteCreatorLinkObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getExperiencesAutoCompleteSuggestionObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        getCreatorSocialListObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        createCreatorSocialObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        updateCreatorSocialObj:{
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
        deleteCreatorSocialObj: {
            status: "idle",
            data: null,
            successMessage: null,
            errorMessage: null,
        },
    },
    reducers: {},
    extraReducers: {
        [getUniqueLinks.pending]: (state) => {
            state.getCreatorLinksObj.status = "pending";
        },
        [getUniqueLinks.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCreatorLinksObj.status = "succeeded";
            state.getCreatorLinksObj.data = data;
            state.getCreatorLinksObj.successMessage = message;
        },
        [getUniqueLinks.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorLinksObj.status = "failed";
            state.getCreatorLinksObj.errorMessage = message;
        },
        [createCreatorUniqueLinks.pending]: (state) => {
            state.createCreatorLinksObj.status = "pending";
        },
        [createCreatorUniqueLinks.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.createCreatorLinksObj.status = "succeeded";
            state.createCreatorLinksObj.data = data;
            state.createCreatorLinksObj.successMessage = message;
            // state.getCreatorLinksObj.data = data;
        },
        [createCreatorUniqueLinks.rejected]: (state, action) => {
            const { message } = action.error;

            state.createCreatorLinksObj.status = "failed";
            state.createCreatorLinksObj.errorMessage = message;
        },
        [deleteCreatorUniqueLink.pending]: (state) => {
            state.deleteCreatorLinkObj.status = "pending";
        },
        [deleteCreatorUniqueLink.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.deleteCreatorLinkObj.status = "succeeded";
            state.deleteCreatorLinkObj.data = data;
            state.deleteCreatorLinkObj.successMessage = message;
            // state.getCreatorLinksObj.data = data;
        },
        [deleteCreatorUniqueLink.rejected]: (state, action) => {
            const { message } = action.error;

            state.deleteCreatorLinkObj.status = "failed";
            state.deleteCreatorLinkObj.errorMessage = message;
        },
        [getExperiencesAutoCompleteSuggestion.pending]: (state) => {
            state.getExperiencesAutoCompleteSuggestionObj.status = "pending";
        },
        [getExperiencesAutoCompleteSuggestion.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getExperiencesAutoCompleteSuggestionObj.status = "succeeded";
            state.getExperiencesAutoCompleteSuggestionObj.data = data;
            state.getExperiencesAutoCompleteSuggestionObj.successMessage = message;
        },
        [getExperiencesAutoCompleteSuggestion.rejected]: (state, action) => {
            const { message } = action.error;

            state.getExperiencesAutoCompleteSuggestionObj.status = "failed";
            state.getExperiencesAutoCompleteSuggestionObj.errorMessage = message;
        },
        [getCreatorSocialList.pending]: (state) => {
            state.getCreatorSocialListObj.status = "pending";
        },
        [getCreatorSocialList.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.getCreatorSocialListObj.status = "succeeded";
            state.getCreatorSocialListObj.data = data;
            state.getCreatorSocialListObj.successMessage = message;
        },
        [getCreatorSocialList.rejected]: (state, action) => {
            const { message } = action.error;

            state.getCreatorSocialListObj.status = "failed";
            state.getCreatorSocialListObj.errorMessage = message;
        },
        [createCreatorSocial.pending]: (state) => {
            state.createCreatorSocialObj.status = "pending";
        },
        [createCreatorSocial.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.createCreatorSocialObj.status = "succeeded";
            state.createCreatorSocialObj.data = data;
            state.createCreatorSocialObj.successMessage = message;
            // state.getCreatorSocialListObj.data = data;
        },
        [createCreatorSocial.rejected]: (state, action) => {
            const { message } = action.error;

            state.createCreatorSocialObj.status = "failed";
            state.createCreatorSocialObj.errorMessage = message;
        },

        [updateCreatorSocial.pending]: (state) => {
            state.updateCreatorSocialObj.status = "pending";
        },
        [updateCreatorSocial.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.updateCreatorSocialObj.status = "succeeded";
            state.updateCreatorSocialObj.data = data;
            state.updateCreatorSocialObj.successMessage = message;
            // state.getCreatorSocialListObj.data = data;
        },
        [updateCreatorSocial.rejected]: (state, action) => {
            const { message } = action.error;

            state.updateCreatorSocialObj.status = "failed";
            state.updateCreatorSocialObj.errorMessage = message;
        },
        [deleteCreatorSocial.pending]: (state) => {
            state.deleteCreatorSocialObj.status = "pending";
        },
        [deleteCreatorSocial.fulfilled]: (state, action) => {
            const { data, message } = action.payload;

            state.deleteCreatorSocialObj.status = "succeeded";
            state.deleteCreatorSocialObj.data = data;
            state.deleteCreatorSocialObj.successMessage = message;
            // state.getCreatorSocialListObj.data = data;
        },
        [deleteCreatorSocial.rejected]: (state, action) => {
            const { message } = action.error;
            state.deleteCreatorSocialObj.status = "failed";
            state.deleteCreatorSocialObj.errorMessage = message;
        },
    },
});

export default uniqueLinkSlice.reducer;

export const uniqueLinkSelector = (state) => state.uniqueLink;

import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import TextButton from "../../../components/button/text-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
    marginTop: "25px",
    marginBottom: "25px",
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

function LastNameForm({ isEditing, setIsEditing, lastNameEdit, setLastNameEdit }) {
    const { getProfileObj, updateProfileObj } = useSelector(profileSelector);

    const toggleEdit = () => {
        setLastNameEdit(true);
        setIsEditing(true);
    };

    const onCancelEdit = () => {
        setLastNameEdit(false);
        setIsEditing(false);
    };

    if (lastNameEdit) {
        return (
            <Grid item xs={12}>
                <SpaceBetweenBox>
                    <Text>Last Name</Text>
                    <TextButton label="Cancel" onClickButton={onCancelEdit} />
                </SpaceBetweenBox>
                <Spacer size="m" position="top" />
                <FormFieldText placeholder="Last Name" name="lastName" />
                <Spacer size="m" position="top" />
                <FormSubmitButton width="100px" isLoading={updateProfileObj.status === "pending"}>
                    <Text type="WhiteColor">Save</Text>
                </FormSubmitButton>
                <SeperateLine />
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <SpaceBetweenBox>
                <Text>Last Name</Text>
                <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={isEditing} />
            </SpaceBetweenBox>
            <Text type="GreyColor">{getProfileObj.data.creator.lastName}</Text>
            <SeperateLine />
        </Grid>
    );
}

LastNameForm.propTypes = {
    setIsEditing: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    setLastNameEdit: PropTypes.func.isRequired,
    lastNameEdit: PropTypes.bool.isRequired,
};

export default LastNameForm;
